import "./App.css";
import SatSomNavbar from "./components/SatSomNavbar";
import { Switch, Route } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Usecase from "./components/Usecase";
import ContactUs from "./components/ContactUs";

function App() {
  return (
    <>
      <SatSomNavbar type="dark" />
      <div className="wrapper">
        <Switch>
          <Route path="/" exact render={(props) => <Header {...props} />} />
          <Route
            path="/usecase"
            exact
            render={(props) => <Usecase {...props} />}
          />
          <Route
            path="/contact"
            exact
            render={(props) => <ContactUs {...props} />}
          />
        </Switch>
        <Footer />
      </div>
    </>
  );
}

export default App;
