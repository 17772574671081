import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import Movipick from "./usecase/Movipick";
import Newshash from "./usecase/Newshash";
import Vocalink from "./usecase/Vocalink";
import EVReus from "./usecase/EVReus";

function Usecase() {
  React.useEffect(() => {
    document.body.classList.add("sections-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("sections-page");
    };
  });
  return (
    <>
      <div className="cd-section">
        <Container>
          <Row>
            <Col className="mx-auto text-center" md="8">
              <h3 className="display-3">Some Usecases</h3>
              <p className="lead"></p>
            </Col>
          </Row>
        </Container>
      </div>
      <EVReus />
      <Newshash />
      <Movipick />
      <Vocalink />
    </>
  );
}

export default Usecase;
