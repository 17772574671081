import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// Core Components

function Movipick() {
  return (
    <>
      <div className="team-1">
        <Container>
          <Row>
            <Col className="text-center mx-auto" md="8">
              <h5 className="display-3">
                <a
                  href="https://movipick.page"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  MoviPick
                </a>
              </h5>
              <p className="lead">
                Movipick is free online latest news and trends search engine. It
                also has user management system to make this experience
                interactive.
              </p>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col lg="6">
              <div className="info info-horizontal info-hover-primary">
                <div className="icon icon-shape icon-shape-info rounded-circle text-white">
                  <i className="ni ni-html5 text-info"></i>
                </div>
                <div className="description pl-4">
                  <h5 className="title">For Movies</h5>
                  <p>
                    Movipick is able to search and recommend movie based upon
                    your interest it uses Elastisearch and spring boot back end
                    to bring you interesting results.
                  </p>
                  <a
                    className="text-info"
                    href="https://movipick.page"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn more
                  </a>
                </div>
              </div>
              <div className="info info-horizontal info-hover-primary">
                <div className="icon icon-shape icon-shape-info rounded-circle text-white">
                  <i className="ni ni-app text-info"></i>
                </div>
                <div className="description pl-4">
                  <h5 className="title">For TV Series</h5>
                  <p>
                    Movipick is able to search and recommend TV series based
                    upon your interest it uses Elastisearch and spring boot back
                    end to bring you interesting results.
                  </p>
                  <a
                    className="text-info"
                    href="https://movipick.page"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn more
                  </a>
                </div>
              </div>
              <div className="info info-horizontal info-hover-primary">
                <div className="icon icon-shape icon-shape-info rounded-circle text-white">
                  <i className="ni ni-bell-55 text-info"></i>
                </div>
                <div className="description pl-4">
                  <h5 className="title">For Trivia</h5>
                  <p>
                    By default Movipick brings you interesting Trivia in a
                    carousel form front end is coded with Flutter Web with
                    spring boot backend.
                  </p>
                  <a
                    className="text-info"
                    href="https://movipick.page"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn more
                  </a>
                </div>
              </div>
            </Col>
            <Col className="mx-md-auto" lg="6" xs="10">
              <img
                alt="..."
                className="ml-lg-5"
                src={require("../../assets/img/movipick.png")}
                width="100%"
              ></img>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Movipick;
