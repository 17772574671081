import React from "react";

// reactstrap components
import { NavbarBrand, Container, Row, Col } from "reactstrap";

// Core Components

function Header() {
  return (
    <>
      <header className="header-5">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand href="/" onClick={(e) => e.preventDefault()}>
              SatSom Consultancy
            </NavbarBrand>
            <button
              aria-controls="navbarSupportedContent"
              aria-expanded={false}
              aria-label="Toggle navigation"
              className="navbar-toggler"
              data-target="#example-header-6"
              data-toggle="collapse"
              id="example-header-6"
              type="button"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
        </Container>
        <div className="page-header">
          <Container>
            <Row className="align-items-center text-left">
              <Col lg="5" xs="12">
                <h1 className="display-3">SatSom Consultancy</h1>
                <h4 className="text-primary">When experience matters.</h4>
                <p className="lead pb-4">
                  Over 15 years of professional software development experience
                  in fintech domain. Together with speciality in front end
                  development as well as cloud native apps we have came long way
                  truly.
                </p>
              </Col>
              <Col className="pl-0" lg="7" xs="12">
                <img
                  alt="..."
                  className="ml-lg-5"
                  src={require("../assets/img/ill/bg6-2.svg").default}
                  width="100%"
                ></img>
              </Col>
            </Row>
          </Container>
        </div>
      </header>
    </>
  );
}

export default Header;
