import React from "react";
// react plugin used to create google maps
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

import axios from "axios";

import HCaptcha from "@hcaptcha/react-hcaptcha";

const MapComponent = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={13}
      defaultCenter={{ lat: 52.370216, lng: 4.895168 }}
      defaultOptions={{
        scrollwheel: false,
        styles: [
          {
            featureType: "administrative",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#444444",
              },
            ],
          },
          {
            featureType: "landscape",
            elementType: "all",
            stylers: [
              {
                color: "#f2f2f2",
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "all",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "all",
            stylers: [
              {
                saturation: -100,
              },
              {
                lightness: 45,
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "all",
            stylers: [
              {
                visibility: "simplified",
              },
            ],
          },
          {
            featureType: "road.arterial",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "transit",
            elementType: "all",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "water",
            elementType: "all",
            stylers: [
              {
                color: "#C5CBF5",
              },
              {
                visibility: "on",
              },
            ],
          },
        ],
      }}
    >
      <Marker position={{ lat: 52.370216, lng: 4.895168 }} />
    </GoogleMap>
  ))
);

function ContactUs() {
  const [formStatus, setFormStatus] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const captcha = React.createRef();

  const [query, setQuery] = React.useState({
    firstname: "",
    lastname: "",
    email: "",
    message: "",
    platform: "",
  });
  React.useEffect(() => {
    document.body.classList.add("contact-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("contact-page");
    };
  });

  const handleChange = () => (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setQuery((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onVerifyCaptcha = (token) => {
    console.log("Verified: " + token);
    setFormStatus(true);
  };

  const doSubmit = (e) => {
    e.preventDefault();
    if (loading) return;
    if (!formStatus) {
      console.log("Please solve the challenge");
      setShow(true);
      return;
    }
    setLoading(true);
    const formData = new FormData();
    Object.entries(query).forEach(([key, value]) => {
      formData.append(key, value);
    });

    axios
      .post(
        "https://getform.io/f/f79ce5a6-6d32-47bf-806b-4caa4a1da291",
        formData,
        { headers: { Accept: "application/json" } }
      )
      .then(function (response) {
        setFormStatus(true);
        setQuery({
          firstname: "",
          lastname: "",
          email: "",
          message: "",
          platform: "",
        });
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  const handleOpen = () => {
    console.log("HCaptcha [onOpen]: The user display of a challenge starts.");
  };

  const handleClose = () => {
    console.log("HCaptcha [onClose]: The user dismisses a challenge.");
  };

  const handleError = (error) => {
    console.log("HCaptcha [onError]:", error);
  };

  const handleChallengeExpired = () => {
    console.log(
      "HCaptcha [onChalExpired]: The user display of a challenge times out with no answer."
    );
  };
  return (
    <>
      <div className="section features-4">
        <Container fluid>
          <Row className="mt-5 mb-4 pt-5">
            <Col className="ml-auto mr-auto text-center mt-5" md="8">
              <Badge color="info">Leave a message</Badge>
              <h1 className="title">
                Tell us more about <b>yourself</b>
              </h1>
              <h4 className="desc">
                Whether you have questions or you would just like to say hello,
                contact us.
              </h4>
            </Col>
          </Row>
          <Row>
            <Col className="mx-auto" md="6">
              <Card
                className="bg-secondary p-3"
                id="contact-form"
                method="POST"
                role="form"
                tag="form"
                onSubmit={doSubmit}
              >
                {formStatus && <Badge color="info">Message Sent</Badge>}
                {show && (
                  <Badge color="danger">Please solve the challenge</Badge>
                )}
                <CardBody>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label>First name</label>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-circle-08"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            aria-label="First Name..."
                            placeholder="First Name..."
                            type="text"
                            name="firstname"
                            value={query.firstname}
                            onChange={handleChange()}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label>Last name</label>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            aria-label="Last Name..."
                            placeholder="Last Name..."
                            type="text"
                            name="lastname"
                            value={query.lastname}
                            onChange={handleChange()}
                          ></Input>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <label>Email address</label>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email Here..."
                        type="text"
                        name="email"
                        value={query.email}
                        onChange={handleChange()}
                      ></Input>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <label>Your message</label>
                    <Input
                      className="form-control-alternative"
                      id="message"
                      name="message"
                      rows="6"
                      type="textarea"
                      value={query.message}
                      onChange={handleChange()}
                    ></Input>
                  </FormGroup>
                  <FormGroup>
                    <Row className={show ? "hcaptcha-error" : ""}>
                      <Col className="text-right" md="6">
                        <HCaptcha
                          sitekey="e14d2dfc-6901-4f86-91db-947322543b37"
                          theme="light"
                          onVerify={onVerifyCaptcha}
                          onOpen={handleOpen}
                          onClose={handleClose}
                          onError={handleError}
                          onChalExpired={handleChallengeExpired}
                        />
                      </Col>
                      <Col className="text-right" md="6">
                        <Button color="primary" type="submit">
                          Send Message
                        </Button>
                      </Col>
                    </Row>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <MapComponent
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyB3HN06j3NouctSsP2KjubLuh2GidfoO7Y&v=3.exp&libraries=geometry,drawing,places"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={
            <div
              className="map skew-separator skew-top"
              id="map-contactus-3"
              style={{
                height: "500px",
                position: "relative",
                overflow: "hidden",
              }}
            />
          }
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div>
    </>
  );
}

export default ContactUs;
