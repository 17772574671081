import React from "react";

// reactstrap components
import { Container, Row, Col, Card, CardImg } from "reactstrap";

// Core Components

function Newshash() {
  return (
    <>
      <div className="team-1">
        <Container>
          <Row>
            <Col className="text-center mx-auto" md="8">
              <h5 className="display-3">
                <a
                  href="https://newshash.nl"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Newshash
                </a>
              </h5>
              <p className="lead">
                Newshash is free online latest news and trends search engine. It
                also has user management system to make this experience
                interactive.
              </p>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col className="mr-auto text-left mt-4" lg="4">
              <Card className="bg-default shadow border-0">
                <CardImg
                  alt="..."
                  src={require("../../assets/img/newshash.png")}
                  top
                ></CardImg>
                <blockquote className="card-blockquote">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="svg-bg"
                    preserveAspectRatio="none"
                    viewBox="0 0 583 95"
                  >
                    <polygon
                      className="fill-default"
                      points="0,52 583,95 0,95"
                    ></polygon>
                    <polygon
                      className="fill-default"
                      opacity=".2"
                      points="0,42 583,95 683,0 0,95"
                    ></polygon>
                  </svg>
                  <h4 className="display-4 text-white">Newshash</h4>
                  <p className="lead text-italic text-white">
                    Realtime search engine for location specific latest trends,
                    tweets & news.
                  </p>
                </blockquote>
              </Card>
            </Col>
            <Col className="p-sm-0" lg="7">
              <Row>
                <Col md="6">
                  <div className="info info-hover-warning">
                    <div className="icon icon-shape bg-warning shadow rounded-circle text-primary">
                      <i className="ni ni-satisfied text-white"></i>
                    </div>
                    <h5 className="info-title">Protects from Fake news</h5>
                    <p className="description opacity-8">
                      Newshash has built in AI powered algorithm to filter out
                      fake news or promotion tweets from the search result.
                    </p>
                  </div>
                </Col>
                <Col md="6">
                  <div className="info info-hover-info">
                    <div className="icon icon-shape bg-info shadow rounded-circle text-primary">
                      <i className="ni ni-palette text-white"></i>
                    </div>
                    <h5 className="info-title">No Advertizement</h5>
                    <p className="description opacity-8">
                      Newshash has very clean and elegant user interface with NO
                      advertizements what so ever either in the search result or
                      on the site itself.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="mt-lg-4">
                <Col md="6">
                  <div className="info info-hover-danger">
                    <div className="icon icon-shape bg-danger shadow rounded-circle text-primary">
                      <i className="ni ni-user-run text-white"></i>
                    </div>
                    <h5 className="info-title">Fast Development</h5>
                    <p className="description opacity-8">
                      Newshash is SPA and also PWA which needs less time to
                      develop and just work with api backend.
                    </p>
                  </div>
                </Col>
                <Col md="6">
                  <div className="info info-hover-success">
                    <div className="icon icon-shape bg-success shadow rounded-circle text-primary">
                      <i className="ni ni-glasses-2 text-white"></i>
                    </div>
                    <h5 className="info-title">Super Fresh</h5>
                    <p className="description opacity-8">
                      Because search results are shown realtime so you get super
                      fresh tweets & trends.
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Newshash;
