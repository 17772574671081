/*eslint-disable*/
import React from "react";

// reactstrap components
import { NavLink, Container } from "reactstrap";

// Core Components

function Footer() {
  return (
    <>
      <footer className="footer footer-simple">
        <Container>
          <nav>
            <ul>
              <li>
                <NavLink href="/" target="_self">
                  SatSom Consultancy
                </NavLink>
              </li>
              <li>
                <NavLink href="/usecase" target="_self">
                  Usecase
                </NavLink>
              </li>
              <li>
                <NavLink
                  href="https://github.com/indianknight"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Github
                </NavLink>
              </li>
              <li>
                <NavLink href="/contact" target="_self">
                  Contact
                </NavLink>
              </li>
            </ul>
          </nav>
          <div className="copyright text-right">
            © {new Date().getFullYear()} &nbsp;
            <a className="copyright-link" href="/" target="_blank">
              SatSom Consultancy
            </a>
            .
          </div>
        </Container>
      </footer>
    </>
  );
}

export default Footer;
