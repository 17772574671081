import React from "react";

// reactstrap components
import { Container, Row, Col, Card, CardImg } from "reactstrap";

// Core Components

function Vocalink() {
  return (
    <>
      <div className="team-1">
        <Container>
          <Row>
            <Col className="text-center mx-auto" md="8">
              <h5 className="display-3">
                <a
                  href="https://vocalinkmoduluschecker.herokuapp.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Vocalink Modulus Checker
                </a>
              </h5>
              <p className="lead">
                Modulus Checker is free online UK bank account number and sort
                code validation tool for the Vocalink based Modulus checker
                algorithm. Front-end is made is React and backend is springboot.
              </p>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col className="mr-auto text-left mt-4" lg="4">
              <Card className="bg-default shadow border-0">
                <CardImg
                  alt="..."
                  src={require("../../assets/img/vocalink.png")}
                  top
                ></CardImg>
                <blockquote className="card-blockquote">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="svg-bg"
                    preserveAspectRatio="none"
                    viewBox="0 0 583 95"
                  >
                    <polygon
                      className="fill-default"
                      points="0,52 583,95 0,95"
                    ></polygon>
                    <polygon
                      className="fill-default"
                      opacity=".2"
                      points="0,42 583,95 683,0 0,95"
                    ></polygon>
                  </svg>
                  <h4 className="display-4 text-white">Modulus Checker</h4>
                  <p className="lead text-italic text-white">
                    Realtime validate your UK bank account number and sort
                    sortcode
                  </p>
                </blockquote>
              </Card>
            </Col>
            <Col className="p-sm-0" lg="7">
              <Row>
                <Col md="6">
                  <div className="info info-hover-warning">
                    <div className="icon icon-shape bg-warning shadow rounded-circle text-primary">
                      <i className="ni ni-satisfied text-white"></i>
                    </div>
                    <h5 className="info-title">Protects from Fake accounts</h5>
                    <p className="description opacity-8">
                      You can instantly check the result of Vocalink algorithm
                      against your account and sort code and protect yourself
                      from fake accounts.
                    </p>
                  </div>
                </Col>
                <Col md="6">
                  <div className="info info-hover-info">
                    <div className="icon icon-shape bg-info shadow rounded-circle text-primary">
                      <i className="ni ni-palette text-white"></i>
                    </div>
                    <h5 className="info-title">No Advertizement</h5>
                    <p className="description opacity-8">
                      Our solution is very clean and elegant user interface with
                      NO advertizements what so ever.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="mt-lg-4">
                <Col md="6">
                  <div className="info info-hover-danger">
                    <div className="icon icon-shape bg-danger shadow rounded-circle text-primary">
                      <i className="ni ni-user-run text-white"></i>
                    </div>
                    <h5 className="info-title">Fast Development</h5>
                    <p className="description opacity-8">
                      Vocalink modulus checker is SPA which needs less time to
                      develop and just work with api backend. Here its spring
                      boot
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Vocalink;
